
import {
  Routes,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import logo from './kalkhoff.png';
import map from './map.png';

const Map = () => (<div>
  <img src={map} alt='map' className="map"></img>
</div>)

const Profile = () => (<div>
  <h1>Profile</h1>
</div>)

const Setting = () => (<div>
  <h1>Settings</h1>
</div>)

const Ranking = () => (<div>
  <h1>Ranking</h1>
</div>)


function App() {
  return (
    <div className="App">
      <div className="header">
        <img src={logo} alt="" className="logo" />
        <h2>DriveWithMe</h2>
      </div>
      <div className="body">
        <Routes>
          <Route path="/map" element={<Map />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Setting />} />
          <Route path="/ranking" element={<Ranking />} />
        </Routes>
      </div>
      <div className="footer">
        <div className="navbar">
          <ul>
            <li>
              <Link className="navbar--link" to="/map">
                Map
              </Link>
            </li>
            <li>
              <Link className="navbar--link" to="/profile">
                Profile
              </Link>
            </li>
            <li>
              <Link className="navbar--link" to="/settings">
                Settings
              </Link>
            </li>
            <li>
              <Link className="navbar--link" to="/ranking">
                Ranking
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
